*{
  padding: 0;
  margin:0;
  box-sizing: border-box;
}
body{
  font-family: "Roboto Slab", serif;
}
a{text-decoration: none;color:inherit}
a:hover{text-decoration: none;color:inherit}
p {
  color: #3333339c;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}
span.bounce1 img { width: 22px; }
.OnlineR a { color: #fff; }
a.parantlogo { padding: 2px 10px; }
.OnlineR { position: fixed; right: 0; z-index: 99; text-align: center; color: #fff; top: 40%; font-family: Open Sans, sans-serif; }
@keyframes bounce1 { 
  0% {
      background-color:#005c9f;
  }
  33% {
      background-color:   #093b60;

  }
  66% {
      background-color:#005c9f;
  }
  100% {
      background-color: #093b60;
  }
}
.bounce1 { border-radius: 25px 0px 0px 25px; color: #fff; font-size: 14px; font-weight: 700; text-align: center; animation: bounce1 1s infinite; padding: 10px 12px; }

.popup {
  padding-right: 0 !important;
}
.popup .carousel-control-prev-icon { background: url('https://webapi.entab.info/api/image/SBSB/public/Images/lefticn.png') no-repeat !important; }
.popup .carousel-control-next-icon { background: url('https://webapi.entab.info/api/image/SBSB/public/Images/righticn.png') no-repeat !important; }
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog { min-width:50%; transform: translateY(-100px); }
.carousel-item .modal-header { background: #002c4c; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain;  height: 800px;  background: #f1f1f1;  width: 100%; }
.carousel-item .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
.carousel-item .modalcard .card-body { padding: 10px }
.carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
.carousel-item .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
.carousel-item button.btn-close:focus { box-shadow: none }
.carousel-item a.btn.btn-primary.btn-sm {background: #002c4c; border: #002c4c; margin: 0 10px; color: #fff!important ;}
.carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #002c4c; }
.carousel-item button.btn-close {position: absolute;z-index: 999;background-color: #002c4c;right: 0;}
.carousel-item .btn-close { background: url('https://webapi.entab.info/api/image/SBSB/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
.modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }
 
.highlights {
  width: 100%;
  background: #FFC633;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.highlights p a {
  color: #000;
  font-size: 15px;
  line-height: 30px;
  font-weight: 300;
}
.highlights p {
  margin-bottom: 0;
}
marquee p { 
  display: inline-block;
}
.header-portalbtn a {
  background: linear-gradient(180deg, #FFC633 0%, #ECAA00 100%);
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0px 6px 10px 0px #00000040;
}
.header {
  display: flex;
  width: 100%;
}

.header-portalbtn {
  align-items: center;
  background: linear-gradient(170.37deg, #005DA0 4.78%, #005a9a 93.85%);
  width: 13%;
  display: flex;
  padding: 30px 0px;
  justify-content: center;
}

.header-info {display: flex;justify-content: flex-end;    width: 10%; margin-left: 25px;}
.header-info-icn a {
  background: #005797;
  margin-bottom: 2px;
  color: #fff;
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  width: max-content;
  font-weight: 300;
  padding: 0px 10px;
}
.header-info-icn {
  justify-content: center;
  display: flex;
  align-items: end;
  flex-direction: column;
}

.header-nav {
  width: 100%;
  display: flex;
}
.logo {
  width: 30%;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.navbar.navbar-expand-lg {
  width: 71%;
  padding: 0;
}

.header-nav .nav-item.active .nav-link:hover {
  color: #fff !important;
}
.header-nav .nav-link {
  color: #004A80 !important;
  font-size: 16px;
  font-weight: 500;
  padding: 40px 0px;
  margin: 0px 3px;
}
.header-nav .nav-link:hover {
  color:#f9bd23 !important;
}
.homeslider {
  position: relative;
  padding-left: 20px;
  background: linear-gradient(170.37deg, #005DA0 4.78%, #002743 93.85%);
}
.homeslider img {
  border-radius: 20px 20px 0px 20px;
}
.homeslider .owl-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
}
.mainbtn {
  background: linear-gradient(170.37deg, #005DA0 4.78%, #002743 93.85%);
  margin:20px auto 0 auto;
  width: max-content;
  padding: 5px 30px;
  border-radius: 3px;
}
.aboutmidsec .maintitle {
  margin: inherit;
}
  .aboutsec {
    padding-left: 80px;
    display: flex;
    justify-content: center;
}
.aboutsecfst .mainbtn {
  margin-bottom: -20px;
}
.aboutsecfst {
  padding: 30px 30px 0px 30px;
  background:url('https://webapi.entab.info/api/image/SBSB/public/Images/aboutimg.png');
  width: 52%;
  margin-top: -100px;
  position: relative;
  z-index: 1;
  border-radius: 10px 10px 0px 10px;
}
.aboutheading {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.aboutheading p {
  color: #003A64;
  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
}
.aboutsecfst:after {
  position: absolute;
  content: ' ';
  width: 100%;
  height: 100%;
  background: #f3b513eb;
  top: 0;
  left: 0;
  border-radius: 10px 10px 0px 10px;
  z-index: -1;
}
.aboutsecscd {
  padding: 0px 70px;
  background: #99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.aboutsecscddesc p span {
  color: #ce2a31;
}
.aboutsecscddesc h5 {
  color: #005491;
}
.aboutsecscddesc p {
  font-size: 40px;
  line-height: 50px;
  color: #333;
  font-style: italic;
}
.aboutsecscddesc img {
  width: 210px;
  background: #f1f1f1;
  border-radius: 8%;
  box-shadow: 0px 0px 0px 5px #dfc7c7;
}
.aboutheading h2 {
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
}
.aboutheading img {
  width: 60px;
  margin-right: 15px;
}
.aboutsecthrd {
  width: 25%;
  background: #99999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutsecthrd .mainbtn img {
  width: 40px;
}
.aboutsecthrd .mainbtn a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainbtn a {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.1px;
}

.maintitle h1 {
  color: #004A80;
  text-align: center;
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
}

.aboutsecscddesc h1 {
  text-align: center;
}
.aboutmiddesc {
  margin-bottom: 25px;
  -webkit-line-clamp: 14;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}
.aboutgf {
  width: 100%;
  border:10px solid #fff;
  box-shadow: 0px 3px 8px 3px #ccc;
  height: 440px;
}
.aboutmidsec {
  padding: 70px 80px;
  background:linear-gradient(-45deg,#ffffffe6,#ffffffe6), url('https://webapi.entab.info/api/image/SBSB/public/Images/aboutmid.png');
}
.aboutmidsec .maintitle h1{
  text-align: left;
}
.aboutmidsec .maintitle h6:before {
  position: absolute;
  width: 25px;
  left: 0;
  transform: translate(0%, -50%);
  margin-left: -30px;
}
.aboutmidsec .maintitle h6 {
  text-align: left;
  margin-left: 30px;
}

.maintitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  width: fit-content;
  margin: 0 auto 25px auto;
  position: relative;
  overflow: hidden;
}
.maintitle h6 {
    letter-spacing: 1.2px;
    position: relative;
    font-size: 14px;
    color: #444444;
    text-transform: uppercase;
    text-align: right;
    margin-bottom: 0;
}
.maintitle h6:before {
  position: absolute;
  height: 5px;
  top: 50%;
  content: ' ';
  background: #f3b516;
  z-index: 9;
  width: 191px;
  left: -205px;
} 

.homenews {
  padding: 70px 0px;
  background:linear-gradient(357deg, white, #f5c4ef,white);
}
.homenewsboxtitle h5 {
  font-size: 20px;
  line-height: 30px;
  display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  color: #004a80;
}
.homenewsboxdesce {
  padding-top: 25px;
}
.homenewsboxdesce p {
  font-size: 18px;
  line-height: 28px;
  display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.attch i:before {
  color: #004a80;
  transform: rotate(45deg);
  font-size: 24px;
  line-height: 23px;
}
.attch {
  width: 43px;
  height: 43px;
  border-radius: 12px;
  padding: 10px;
  background: #f3b516;
}
.homenewsboxtitle {
  margin-top: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;
}
.homenewsboxdate {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.homenewsboxdate span {
  font-size: 74px;
  color: #ffffff73;
  font-weight: bold;
}
.managementsecbox {
  margin-top: -110px;
  text-align: center;
}
.managementsec .managementsecbg{
  padding-top: 70px;
  padding-bottom: 200px;
  background: linear-gradient(0deg, rgba(0, 0, 0, .3), 10% , rgba(0, 0, 0, .3) 90% ) ,url('https://webapi.entab.info/api/image/SBSB/public/Images/managementbg.jpg') no-repeat bottom right!important;

}
.managementsec p {
  text-align: center;
}
.managementsecbox img {
  width: 205px;
  height: 205px;
  box-shadow: 0px 14px 12px -9px #00000073;
  background: linear-gradient(180deg, #FFC633 0%, #ECAA00 100%);
  padding: 2px;
  margin-bottom: 20px;
}
.managementsec {
  padding-bottom: 70px;
}
.managementsecbox h5 {
  color: #002C4C;
  font-size: 20px;
  line-height: 30px;
}
.homeevent {
  padding: 70px 0px;
  background:linear-gradient(0deg, rgba(255, 255, 255, .8), 10% , rgba(255, 255, 255, .8) 90% ) , url('https://webapi.entab.info/api/image/SBSB/public/Images/eventbg.jpg') no-repeat bottom right!important;
}

.homenews h2 span {
  display: block;
}
.homenewsbox:hover {
  border-bottom: 10px solid #f3b516;
}
.homenewsboxdesc p {
  font-size: 16px;
  line-height: 28px;
  color: var(--black);
  padding: 0px 10px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0px;
}
.homenewsbox {
  transition: all 0.3s ease-in;
  position: relative;
  border-radius: 15px;
  text-align: center;
  margin: 0px 10px 20px 10px;
  padding: 20px 20px 10px 20px;
  background: #ffffff7a;
  border-bottom: 10px solid transparent;
  box-shadow: 0px 1px 13px 1px #cccccc3d;
}
.homeeventbox {
  position: relative;
  border-radius: 10px 10px 20px 20px;
  text-align: center;
  margin: 6px 10px 40px 0px;
  padding: 10px 0px 0px 0px;
  box-shadow: 0px 1px 4px 1px #ccc;
  background: #d1e2d66e;
}
.homeeventboxtitle p {
  color: #444;
  font-weight: bold;
  font-size: 20px !important;
  padding: 5px 5px;
  text-align: center;
  margin-bottom: 0;
  line-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.homeeventboximg img {
  border-radius: 10px;
  height: 190px;
  padding: 0px 10px;
  width: 100% !important;
  margin-bottom: 10px;
}
.homeeventboxdesc {
  padding: 5px 0px 0px 0px;
  min-height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.homeeventboxdesc p {
  font-size: 16px;
  line-height: 28px;
  color: var(--black);
  padding: 0px 10px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0px;
}
.homeeventboxbottom {
  display: flex;
  box-shadow: 0px -6px 3px -4px #00000024;
  justify-content: space-between;
  padding: 10px 10px 10px 10px;
  background: linear-gradient(180deg, #FFC633 0%, #ECAA00 100%);
  border-radius: 0px 0px 20px 20px;
}
.homeeventboxbottom span i {
  color: #1a4872;
}
.homeeventboxdate {
  top: -3px;
  position: absolute;
  background: linear-gradient(180deg, #FFC633 0%, #ECAA00 100%);
  padding: 10px;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0px);
  border-radius: 0px 0px 10px 10px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}

.homemsgtitle {
  position: relative;
}

.homemsgtitle .maintitle {
  position: absolute;
  top: 20px;
  left: 30px;
}
.messagebox {
  background: #EFEFEF;
  position: relative;
  width: 70%;
  padding: 0px 60px 35px 60px;
  margin: 0 auto;
}
.messageboximg img {
  width: 150px !important;
}
.principalimgbox {
  position: absolute;
  top: 0;
  width: 220px;
  left: -160px;
}
.homemsg{ padding: 120px 0px 50px 0px;}
.messagetop {
  display: flex;
  justify-content: space-between;
}

.messageboximg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 30px;
}
.messagedesc {
  padding: 0 30px 0px 30px;
}
.principalimgbox img {
  width: 100%;
  height: 200px;
  border: 2px solid #f3b516;
  object-fit: contain;
  background: #f1f1f1;
}
.messageboxtitle h3 {
  font-size: 30px;
  line-height: 35px;
  color: #fff;
  padding-left: 20px;
  margin-bottom: 0;
}
.messageboxtitle {
  background: #004A80;
  border-radius: 0px 0px 40px 0px;
  display: flex;
  align-items: center;
  padding: 20px 60px 20px 30px;
  margin-top: 0;
  margin-bottom: 50px;
  width: max-content;
  position: relative;
  margin-left: 0;
  text-transform: uppercase;
}
.messageboximg p span {
  color: #333333ad;
  font-weight: 400;
  display: block;
}

.messageboximg p {
  color: #29335D;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}

.homemsg .owl-nav .owl-next,.homemsg .owl-nav .owl-prev {
  font-size: 18px !important;
}
.homemsg .owl-nav .owl-next, .homemsg .owl-nav .owl-prev {
  position: absolute;
}
.homemsg .owl-nav .owl-next {
  top: -50px;
  left: 21%;
  transform: translate(-36%, 0%);
}
.homemsg .owl-nav .owl-prev {
  top: -50px;
  left: 19%;
  transform: translate(-50%, 0%);
}
.homemsg .owl-theme .owl-nav .disabled {
  opacity: 1;
}
.homemsg .owl-carousel .owl-nav button.owl-prev, .homemsg .owl-carousel .owl-nav button.owl-next {
  padding: 7px 15px 11px 12px !important;
  border-radius: 0;
  color: #000;
  background: linear-gradient(180deg, #FFC633 0%, #ECAA00 100%);
}
.homemsg .owl-theme .owl-nav [class*='owl-']:hover {
background: #f6b91c;
color: #FFF;
text-decoration: none;
transition: all 0.5s ease-in;
}
.facilitiesfstbox p {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

.facilitiesfstbox {
  margin-bottom: 30px;
}

.facilitiesbox h3 {
  font-size: 20px;
  color: #004a80;
  line-height: 30px;
  margin-bottom: 25px;
  margin-top: 35px;
  text-align: center;
  height: 40px;
}

.facilitiesbox p {
  font-size: 17px;
  line-height: 30px;
}

.facilitiesbox  img {
  width: 140px;
  margin: 0 auto;
  display: block;
}

.facilities .container-fluid {
  background: linear-gradient(-45deg, rgba(255, 255, 255, .9), 90% , rgba(255, 255, 255, .8) 90% ) , url('https://webapi.entab.info/api/image/SBSB/public/Images/facilitesbg.jpg') no-repeat bottom right!important;
  background-size: 100% 70% !important;
  margin-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.facilities {
  padding: 70px 0px 70px 0px;
}

.toopersec .toopersecbg{ padding: 75px 0px 90px 0px;background:linear-gradient(180deg, rgba(0, 93, 160, .9), rgba(0, 39, 67, .9)) ,  url('https://webapi.entab.info/api/image/SBSB/public/Images/topperbg.jpg') no-repeat !important; background-size: cover !important; }

.topperbox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  margin-top: 30px;
}

.topperdiv {
  margin-top: 20px;
  margin-bottom: 60px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.topperimages img {
  border-radius: 80px;
  width: 140px !important;
  height: 140px;
  background: linear-gradient(170.37deg, #005DA0 4.78%, #002743 93.85%);
  margin-bottom: 20px;
  padding: 8px;
}
.topperimages {
  position: relative;
}

.topperdesc p {
  text-align: center;
  color: #fff;
  font-weight: 400;
  margin-bottom: 4px;
}
.tooperpicicn {
  margin-top: -167px;
  width: 200px !important;
}
.topperdesc p span {
  display: block;
  font-weight: 300;
}
.per {
  font-weight: 500;
}
.toppermaintitle {
  text-align: center;
}
.toopersec .owl-dots {
    position: absolute;
    display: flex;
    top: 50%;
    flex-direction: column;
}
.toopersec {
    position: relative;
}
.topperrib {
  display: block;
  margin: 10px auto 30px auto;
}
.topper10bx .owl-dots {
  right: 20px;
}
.homegallery {
  padding:90px 0px 90px 0px;
  background: linear-gradient(-45deg, rgba(255, 255, 255, .7), rgba(255, 255, 255, .7)) , url('https://webapi.entab.info/api/image/SBSB/public/Images/gallerybg.jpg') no-repeat !important;
}
.gallerysectitle {
  display: none;
}
.gallerysectitle h3 {
   visibility: hidden;
  font-size: 18px !important;
  padding: 15px 5px 5px 5px;
  text-align: center;
  margin-bottom: 0;
  color: #333;
  line-height: 30px;
}
.gallerysec {
  border-radius: 4px;
  margin: 10px 6px;
}

.gallerysecimg img {
  background: linear-gradient(180deg, #FFC633 0%, #ECAA00 100%);
  padding: 4px;
  border-radius: 9px;
  height: 250px;
  width: 100%;
}
button.owl-dot.active span {
  width: 10px !important;
  height: 10px !important;
}

button.owl-dot span {
  width: 7px !important;
  height: 7px !important;
}
.homegallery button.owl-dot.active span {
  background: #004A80 !important;
}
.homegallery button.owl-dot span {
  background: #004a8082 !important;
}
.owl-theme .owl-dots .owl-dot span {
  margin: 5px 2px;
 }
  .homegallery .owl-dots {
    text-align: right;
    margin-top: 20px !important;
    margin-bottom: 20px;
}

.footerinfo {
  position: absolute;
  width: 20%;
  top: -60px;
  right: 120px;
  padding: 20px;
  background: linear-gradient(180deg, #FFC633 0%, #ECAA00 100%);
  text-align: center;
  box-shadow: -12px 11px 15px -9px #00000059;
}
.footer iframe {
  height: 240px;
  border: 5px solid #fff;
}
.app-cont {
  width: 70%;
}

.app-cont img {
  width: 130px !important;
}

.phone-img img {
  width: 100% !important;
}

.phone-img {
  width: 30%;
}
.footer .d-flex {
  align-items: flex-end;
}
.footer .link{
  font-size: 14px;
  color: #000;
  padding: 5px 10px;
  border-radius: 8px;
  background: linear-gradient(173deg, #ffb800, #ef8f00);
  margin-top: 16px;
  width: 95%;
}
.copyright p {
  text-align: center;
  margin-bottom: 0;
  font-size: 13px;
  color: #fff;
}
.footerinfo p {
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  color: #333;
}
.footerinfo .col-lg-6:first-child {
  border-right: 1px solid #ffffff6e;
}
.footerinfo h5 {
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 10px;
  color: #333;
}
.footer {
  color: #fff;
  position: relative;
  padding: 70px 0px 0px 0px;
  background: linear-gradient(170.37deg, #005DA0 4.78%, #002743 93.85%);
}
.footerinfo h6 {
  color: #004A80;
  font-size: 16px;
  font-weight: 700;
}
.quick-link li {
  font-weight: 300;
  list-style: none;
  font-size: 16px;
  line-height: 30px;
}

.quick-link {
  margin-top: 75px;
  display: flex;
  flex-wrap: wrap;
}

.cont-dtl {
  margin-top: 30px;
  padding-left: 30px;
  margin-bottom: 30px;
}

.cont-dtl p {
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  font-weight: 300;
}
.quick-link ul {
  width: 50%;
  padding-left: 10px;
}
.cont-dtl h3 {
  padding-left: 10px;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  position: relative;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.app-cont p {
  color: #fff;
}
.divider {
  border-right: 1px solid #cccccc1c;
}
.copyright {
  background: #053150;
}

.copyright p a {
  color: #fbbf27;
}
.fulwidth {
  width: calc(90% - 40px);
}
p.hours {
  margin-bottom: 0;
  box-shadow: -12px 11px 15px -9px #00000059;
  background: linear-gradient(180deg, #FFC633 0%, #ECAA00 100%);
  width: max-content;
  padding: 9px 10px;
  color: #333;
}
.footerbottom ul {
  padding-left: 0;
  display: flex;
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
}
.footerbottom {
  background: #00243E;
}
.footerbottom ul li:last-child {
  border-right: 0px solid #cccccc38;
}
.footerbottom ul li {
  padding: 0px 20px;
  font-weight: 300;
  font-size: 14px;
  border-right: 1px solid #cccccc38;
}
.messagedesc {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.clearfix p.name span {
  display: block;
  font-weight: bold;
  color: #b29a1c;
}

.clearfix p.name {color: #333;font-weight: bold;}
.msgimg{
  height: 330px;
  object-fit: contain;
  border: 3px double #ccc;
  padding: 5px;
  border-radius: 7px;
  margin-left: 20px;
}
.chooseyear {
  width: max-content;
  float: right;
  margin-bottom: 30px; 
}
.clr{clear: both;}
#chooseddlYear option { color: black;}
.chooseyear  select{margin:0px;}
.chooseyear  select{width:100%;background: unset;border: unset;height:28px; margin-top:15px;border: unset;border-bottom: 1px solid grey;}
.navbar-nav .dropdown-item:hover {
  background-color: #eead05;
}
.header-nav .nav-item.active .nav-link,a.nav-link.dropdown-toggle.active {
  color: #fff !important;
  animation : color  5s ease-in 0s infinite;
}


@keyframes color{
  0% {
    background: #c5148e;
}
20% {
    background: #0c520c;
}
40% {
    background: #1010c9;
}
60% {
    background: #ffc633;
}
80% {
    background: #d79112;
}
100% {
    background: #d50d0d;
}
}

.innerslide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(360deg, #1c1b3b8f 100%, #1c1b3b91 100%, #3333338c 100%),url('https://webapi.entab.info/api/image/SBSB/public/Images/innersld.jpg') no-repeat center center;
  background-size: cover;
  height: 255px;
  padding-bottom: 50px;
}
.innersec {
  min-height: 400px;
  padding: 60px 10px;
}
.innersec p {
  line-height: 34px;
  font-size: 16px;
  color: #4e4e4e;
}
.innersec h1 {
  font-size: 35px;
  position: relative;
  padding-left: 70px;
  background: -webkit-linear-gradient(#eead06, #ae8211);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 45px;
  padding-bottom: 20px;
}
.innersec h5 {
  font-size: 20px;
  color: #0664a8;
  line-height: 30px;
}
.innersec h1:after {
  position: absolute;
  content:  ' ';
  width: 60px;
  height: 3px;
  background:-webkit-linear-gradient(#eead06, #ae8211);
  top: 20px;
  bottom: 0;
  left: 0;
}

.breadcrumb li:before {
  position: absolute;
  content: ' \F138 ';
  width: 100%;
  height: 100%;
  font-family: bootstrap-icons;
  left: -15px;
}
.breadcrumb li:first-child:before {
  content: "\f404";
  background: #cf9705;
  width: 30px;
  border-radius: 20px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -25px;
  top: -3px;
}
.breadcrumb li a {
  color: #f9f9f9;
  position: relative;
}
.breadcrumb li {
  padding: 0px 15px 0px 15px;
  color: #fff;
  position: relative;
  margin-left: 7px;
  font-size: 14px;
}
ul.breadcrumb {
  margin-bottom: 0;
}
.navbar .nav-item .dropdown-menu {
  margin-top: -30px !important;
}
.mainlist {
padding-left: 0;
display: flex;
flex-direction: column;
justify-content: space-between;
}
.mainlist li{
  padding-left: 29px;
  position: relative;
  list-style: none;
  font-size: 16px;
  margin: 5px 0px;
  line-height: 30px;

}
.mainlist  li:before {
  content: "";
  width: 15px;
  height: 15px;
  background: radial-gradient(#ffffff, #e7bb3e, #ffffff);
  position: absolute;
  border-radius: 50%;
  left: 0px;
  top: 8px;
}
.rubylogo {bottom:380px;right: 5px;position: fixed;z-index: 99;width: 140px;}
.rubylogo img {width: 140px;}
.dropdown-item.active, .dropdown-item:active {
  background-color: #005a9a;
}
.contactdiv { width: 65%;}
.enquiryformimg{width: 35%;background: #d2eaff ;padding-left: 20px;padding-right: 20px;} 
.enquiryform {flex-wrap: wrap;display: flex;flex-direction: row;justify-content: center;width: 100%;background: #fff;} 
.enquiryformsec {/* width: 50%; *//* background: #f1f1f1; *//* padding: 30px; *//* display: flex; *//* flex-direction: column; *//* justify-content: center; *//* align-items: center; */}
.locationbox {margin-top: 65px;} .locationicon { padding: 0px 7px; width: 45px; } 
.locationicon i {color: #246097;font-size: 30px;} 
.locationdiv {display: flex;margin-bottom: 20px;border-bottom: 1px dashed #215b8f;}
.locationdetails { margin-left: 5px; width: 90%; margin-bottom: 10px; } 
.locationdiv h5 {font-weight: 600;color: #246097;font-size: 19px;text-transform: capitalize;}
.locationdiv p a { color: #000; font-size: 16px; margin-bottom: 0; }
.locationdiv:last-child { border-bottom: 0px dashed #215b8f;}
.row.tabs-dynamic {
  background: #f1f1f1;
  padding: 10px;
  margin: 0px 0px 40px 0px;
}

.row.tabs-dynamic p {
  margin-bottom: 0;
}

.row.tabs-dynamic select {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 8px 5px;
  border-bottom: 1px solid #b6b6b6;
}

.row.tabs-dynamic input {
  padding: 9px 5px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #b6b6b6;
  background: transparent;
}

.count-val p {
  margin-bottom: 0px;
}
.row.tabs-dynamic p a {
  color: #333;
}

.gallery-inner {
  background: #005593;
  margin-bottom: 30px;
  box-shadow: 7px 6px 12px -4px #ddd;
  /* position: relative; */
  overflow: hidden;
  transition: all 0.5s ease-in;
}
.gallery-blks a {
  color: inherit;
}
.gallery-blks img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}
.gallery-title h3::after {
  position: static;
}
.gallery-inner:hover .gallery-content {
  bottom: 0;
}
.gallery-blks {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
} 

.gallery-title p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 28px !important;
  font-size: 16px !important;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gallery-date {
  display: flex;
}

  .gallery-date h6 {
      color: #fff;
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 25px;
      background: #fc097c;
      padding: 5px 10px;
      border-radius: 5px;
      font-family: 'Poppins', sans-serif;
      margin-top: 0;
  }

.gall-num h5 {
  background: transparent;
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 25px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}
.galleryCount:nth-child(2n+2) .gallery-inner .gallery-date h6 {
  background: #f9d464;
}

.galleryCount:nth-child(2n+3) .gallery-inner .gallery-date h6 {
  background: #00acee;
}

.galleryCount:nth-child(2n+4) .gallery-inner .gallery-date h6 {
  background: #198754;
}
 
.gallery-content {
  padding: 20px 15px;
  position: relative;
  background: #fff;
  width: 96%;
  margin: -30px auto 10px auto;
}
 
.gallery-title h3 {
  font-size: 18px;
  color: #b78c0b;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background: transparent;
}
h4.gallery-title {
  color: #000000bf;
  font-size: 20px;
  line-height: 30px;
  font-family: "Inter", sans-serif;
}
.sub-gallerycount img {
  border: 3px double #ccc;
  background: #f1f1f1;
  height: 200px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
  padding: 4px;
}
.eventbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #f1f1f1bd;
  box-shadow: 2px 0px 8px 0px #ccc;
  margin-bottom: 20px;
}

.eventboximg {
  width: 40%;
  margin-right: 5px;
  position: relative;
}

.eventboxdesc {
  width: 58%;
}
.eventpgh {
  height: 125px;
  overflow-y: scroll;
  padding-top: 13px;
}

.eventboximg img {
  border: 10px solid #f1f1f1bd;
  height: 165px;
  object-fit: contain;
  background: #fff;
  width: 100%;
}
.eventdate {
  display: flex;
  justify-content: space-between;
  padding: 8px 10px 8px 5px;
}
.eventpgh p {
  font-size: 15px;
  line-height: 30px;
}
.eventdate a {
  border-radius: 5px 3px 6px 0px;
  color: #fff;
  padding: 2px 5px;
}
.eventdate span {
  font-size: 13px;
  line-height: 23px;
  color: #1c1b3b;
}
.eventpgh h5 {
  color: #9e790c;
  line-height: 30px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.eventdate span i {
  color: #1c1b3b;
  position: relative;
  bottom: 3px;
}
.eventpgh::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.eventpgh::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}
.eventattch {
  background: #f49a00;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px 5px 4px 5px;
  border-radius: 0px 0px 10px 10px;
  color: #fff;
}

.newscountbox {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 40px;
}

.news-block {
  width: 100%;
  position: relative;
  padding: 20px 10px 15px;
  background-color: #ffffffcf;
}

.newsCount .attachment {
  position: absolute;
  right: 0;
  bottom: 0px;
  background: #005694;
  padding: 8px 10px;
  border-radius: 3px 10px 3px 10px;
}

.newsCount .attachment a {
  color: #fff;
}

.newsCount {
  width: calc(51% - 35px);
  position: relative;
  padding: 15px;
  display: flex;
  background-color: #f2f4f7;
  justify-content: center;
  margin: 10px 10px;
}

.newsCount:before {
  position: absolute;
  left: 0;
  top: 0%;
  height: 0;
  width: 100%;
  content: "";
  background: linear-gradient(to right, #ff4200 0%,rgba(69,74,180,1) 50%,#ffc600 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f14b59', endColorstr='#ffa200',GradientType=1 );
  transition: all 500ms ease;
}
.newsCount:hover:before{
height: 100%;
  top: 0;
}
.news-head h4 {
  padding: 10px 20px;
  font-size: 15px;
  background: #eea201;
  width: max-content;
  border-radius: 5px;
  color: #fff;
}
.news-head h3 {
  padding-left: 0;
  font-size: 16px;
  position: static;
  background: transparent;
  color: #000;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  padding-bottom: 0;
  font-family: 'Poppins';
}

.news-head h3:after {
  position: static;
}
row.years {
  margin-bottom: 30px;
}
.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
.tcblock {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
} .tc {
  width: 49%;
  margin-bottom: 30px;
} .tcblock iframe {
  width: 49%;
} .tc label {
  color: #000;
  width: 100%;
  margin-bottom: 0;
} .tc label p {
  margin-bottom: 0px;
} .tc .form-group {
  margin-bottom: 20px;
} .form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
} a.viewdetailsbtn {
  color: #fff;
  font-size: 14px;
} .viewdetailsbtn {
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
  background:linear-gradient(131deg, #bf8f12, #1d557e);
  transition: all 0.2s ease-in;
} .viewdetailsbtn:hover {
  background:linear-gradient(131deg, #bf8f12, #1d557e);
}
.verticaltabs .nav-pills .nav-link.active {
  background: -webkit-linear-gradient(#bfd875, #006e27);
  color: #fff;
}
.erp_sec .guideheading {
  text-align: left;
}
#myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s;
}
.erp_sec img { 
  display: inline-block;
}
.erp_sec h4.imgheading {
  text-align: center;
}
.assistance {
  background: #eaeeff;
  padding: 20px;
  margin-top: 30px;
}
.erp_sec h4 {
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 700;
  font-family: 'Roboto',sans-serif;
}

.assistance {
  background:rgb(230 243 255);
  padding: 13px;
  border: solid 1px #ddd;
  margin-top: 20px;
}
.erpbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.erpboxdesc {
  width: 72%;
}

.erpboximg {
  width: 26%;
}

.erpboxdescpopup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.erppopup {
  width: 30%;
  margin: 10px;
}

.imgheading a { 
  text-align: center;
  width: 100%;
  display: block;
  font-size: 20px;
  padding: 10px 0;
}

.erppopup .modal-body h5 {
  color: #000;
  text-align: center;
  padding-top: 20px;
}
@media all and (min-width: 992px) {

	.navbar .dropdown-menu-end{ right:0; left: auto;  }
	.navbar .nav-item .dropdown-menu{    padding: 0;
    border-radius: 2px;display:block; opacity: 0;  visibility: hidden; transition:.3s; margin-top:0;  }

	.navbar .dropdown-menu.fade-down{ top:80%; transform: rotateX(-75deg); transform-origin: 0% 0%; }
	.navbar .dropdown-menu.fade-up{ top:180%;  }
	.navbar .nav-item:hover .dropdown-menu{ transition: .3s; opacity:1; visibility:visible; top:100%; transform: rotateX(0deg); }
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.app-cont div {
  display: flex;
}
.mainsld-overlay {
  position: fixed;
  z-index: 99999;
  background: url(https://webapi.entab.info/api/image/SBSB/public/Images/slidepopupbg.jpg);
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
 
.closeLoader i{
  cursor: pointer;
  font-size: 30px ;
}

.mainsld-overlay > div {
  position: absolute;
  z-index: 9999;
}

.enter-sec-details img {
  display: block;
  margin: auto;
  max-width: 90%;
}
 
.closeLoader {
  position: fixed !important;
  right: 10px;
  top: 10px;
  color: #fff;
  z-index: 999;
}

.firework-animation {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}
 .popuplogo {
  width: 100%;
  max-width: 100%;
  height: 130px;
}
@media(max-width: 1668px){
  .facilitiesbox h3 {
    height: 80px;
}
  nav.navbar.navbar-expand-lg {
    width: 77%;
}
.logo {
    width: 27%;
    padding-right: 5px;
    padding-left: 5px;
}
 }

 @media(max-width: 1600px){
  .footerinfo {
    width: 25%;
  }
  .fulwidth {
    width: calc(100% - 26px);
}
  .header-portalbtn {
    padding: 24px 0px;
  }
  .header-info-icn a {
    font-size: 12px;
}
.header-nav .nav-link {
    font-size: 15px;
    margin: 0px 0px;
}
.aboutsecfst {
  width: 100%;
}
 }


 @media(max-width: 1400px){
  .header-nav .nav-link {
    font-size: 13px;
    padding-right: 5px !important;
    padding-left: 5px !important;
}
  .logo {
    width: 50%;
    padding-right: 20px;
    padding-left: 0px;
  }
  .quick-link li {
    font-size: 15px;
  }

.header-portalbtn a {
  padding: 9px 12px;
  font-size: 12px;
}
.header-portalbtn {
  width: 10%;
  padding: 20px 0px;
}
nav.navbar.navbar-expand-lg {
  width: 90%;
}
.aboutsecscd {
  padding: 0px 35px;
}
 }

 @media (max-width: 1292px){
  .header-nav .nav-item.active .nav-link {
    animation: none;
}
.header-nav .nav-link {
    font-size: 12px;
}
}
@media(max-width: 1254px){
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 5px;
  padding-left: 5px;
}

.header-portalbtn a {
  padding: 9px 12px;
  font-size: 10px;
}
}
@media (max-width: 1246px){
.logo {
    width: 40%;
}
}
@media(max-width: 1200px){
  .contactdiv {
    width: 60%;
}
   .enquiryformimg {
    width: 40%;
}
  .header-nav .nav-link {
    padding: 15px 0px;
  }
  .messagebox {
    width: 65%;
  }
  .footerinfo {
    width: 32%;
    right: 40px;
}
  .footer .d-flex { 
    margin-top: 0;
  }
  .aboutmidsec {
    padding: 70px 0px;
  }
  .aboutsec {
    padding-left: 0;
    margin: 0px 15px;
}
  .aboutsec {
    flex-wrap: wrap;
  }
  .aboutsecscd {
    padding: 40px 35px;
}
.aboutsecthrd {
  width: 100%;
  padding: 15px 0px;
}
  .header-nav {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.header-portalbtn {
  width: 20%;
  padding: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-portalbtn a {
  font-size: 14px;
}
nav.navbar.navbar-expand-lg {
  width: 100%;
  padding-left: 20px;
}
.logo {
  width: 50%;
}
.navbar-expand-lg .navbar-collapse {
  justify-content: center;
}
}
@media (max-width: 992px){
  .contactdiv, .enquiryformimg {
    width: 100%;
}
  .managementsecbox {
    margin-top: 30px;
  }
  .managementsec .managementsecbg {
    padding-bottom: 50px;
  }
  .messageboxtitle {
    margin-bottom: 0;
  }
  .messagebox {
    width: 100%;
    padding: 0px 0px 35px 0px;
  }
  .principalimgbox {
    position: static;
    width: 300px;
    margin: 20px auto 20px auto;
}
  .aboutgf {
    margin-top: 20px;
  }
  nav.navbar.navbar-expand-lg button.navbar-toggler {
    position: absolute;
    top: -80px;
}
.navbar-collapse {
  padding-top: 10px;
  padding-bottom: 10px;
  position: absolute;
  top: 0;
  background: #005c9d;
  width: 100%;
  z-index: 9;
  left: 0;
  padding-left: 20px;
}
.header-nav .nav-link {
  color: #fff !important;
}
  .homegallery {
    padding: 90px 0px 130px 0px;
  }
  .footerinfo {
    width: 45%;
  }
.header-nav {
  align-items: flex-start;
    flex-wrap: wrap;
}
.header {
  position: relative;
  flex-wrap: wrap;
}
.header-portalbtn {
  order: 2;
}
.logo {
  width: 100%;
}
.header-info {
  order: 3;
}
.header {
  flex-wrap: wrap;
}
.header-portalbtn {
  order: 2;
}
.logo {
  width: 100%;
}
.header-info {
  order: 3;
}
nav.navbar.navbar-expand-lg {
  width: 100%;
  padding-left: 0;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
.header-info {
  width: 80%;
  margin-left: 0px;
}
.app-cont img {
  width: 120px!important;
}
.app-cont img {
  width: 120px!important;
}
.footer .link {
  text-align: center;
  font-size: 13px;
}
.footerbottom ul {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
}
}
@media(max-width: 767px){
  .rubylogo img {
    width: 100px;
}
.rubylogo { 
  right: 0; 
  width: auto;
}
  .newsCount {
    width: 100%;
  }
  .toopersec .owl-dots {
    position: static;
    display: block;
  }
  .phone-img {
    width: 15%;
}
  .cont-dtl {
    padding-left: 0px;
  }
  .facilities {
    padding: 50px 0px 50px 0px;
}
  .homemsg .owl-nav .owl-next, .homemsg .owl-nav .owl-prev {
    position: static;
}
  .facilitiesbox h3 {
    height: auto;
}
  .aboutsecfst {
    margin-top: 20px;
  }


  .managementsecbox {
    margin-top: 30px;
  }
  .managementsec {
    padding-bottom: 50px;
}
  .managementsec .managementsecbg {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .homemsg {
    padding: 50px 0px 0px 0px;
}
  .toopersec .toopersecbg {
    padding: 50px 0px 50px 0px;
  }
  .homegallery {
    padding: 50px 0px 50px 0px;
}
  .footer {
    padding: 0px 0px 0px 0px;
  }
.footerinfo {
  width: 100%;
  position: static;
  margin-bottom: 30px;
}
}

@media(max-width: 567px){
  .popuplogo { 
    height: auto;
  }

  .innersec h1 {
    font-size: 26px; 
    padding-left: 35px; 
    line-height: 36px; 
}
  .innersec h1:after { 
    width: 30px;
  }
  .locationdiv p a { 
    font-size: 15px; 
    line-height: 30px;
}
  .locationbox {
    margin-top: 25px;
}
  .enquiryformimg { 
    padding-left: 10px;
    padding-right: 10px;
}
  .messageboximg img {
    width:100px !important;
}


  .header-nav {
    justify-content: flex-start;
  }
  .header-info {
    width: 70%;
  }
  .logo {
    width: 78%;
}
  .header-portalbtn {
    width: 30%;
  }

.aboutsecscd {
  padding: 40px 20px 0px 20px;
}
.homeslider {
  padding-left: 0;
}
}

@media(max-width: 420px){
  .messagedesc {
    padding: 0 15px 30px 15px;
}
  .messageboximg {
    padding-left: 15px;
}
  .messageboxtitle h3 {
    font-size: 23px;
    line-height: 33px;
    padding-left: 10px;
  }
  .messageboxtitle {
    width: 100%;
    padding: 15px 10px 15px 10px;
  }
  .fulwidth {
    width: 100%;
}
  .maintitle {
    margin: 0 auto 5px auto;
  }
  .maintitle h1 {
    font-size: 23px;
    line-height: 33px;
  }
  .aboutsecfst:after {
    border-radius: 0;
  }
  .aboutheading {
    flex-wrap: wrap;
  }
  .aboutsecfst {
    padding: 30px 15px 0px 15px;
  }
  .aboutheading h2 {
    text-align: center;
    font-size: 23px;
    line-height: 33px;
}
.header-info {
  width: 60%;
}
.header-portalbtn {
    width: 40%;
    background: transparent;
}
}

@media(max-width: 400px){
  nav.navbar.navbar-expand-lg button.navbar-toggler {
    top: -50px;
}
  .app-cont, .phone-img {
    width: 100%;
}
}